@import "src/styles/_defines";

.partnersSectionComponent {
    max-width: 1100px;
    margin: 150px auto;
    h2 {
        font-size: 41px;
        text-align: center;
    }
    .clientsContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 75px;
        .clientCard {
            width: 264px;
            max-width: 264px;
            height: 284px;
            min-height: 284px;
            padding: 35px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 40px;
            .clientLogo {
                display: flex;
                align-items: center;
                min-height: 80px;
                img {
                    max-width: 100%;
                    max-height: 78px;
                }
            }
            .contentCard {
                text-align: center;
                h4 {
                    margin: 0;
                    margin-bottom: 10px;
                    font-size: 23px;
                }
                .locationsRow {
                    display: flex;
                    align-items: center;
                    max-height: 50px;
                    span {
                        font-size: 17px;
                        margin: 0px 20px;
                    }
                }
            }
            .filledButton {
                .MuiButton-label {
                    color: #fff !important;
                    font-size: 19px !important;
                    font-weight: 600 !important;
                    background: none !important;
                    -webkit-text-fill-color: #FFF !important;
                }
            }
        }
    }
    // Max laptop
    @media (max-width: $screen-md-max) {
        padding: 0px 16px;
        .clientsContainer {
            justify-content: space-evenly;
        }
    }
    @media (max-width: 767px) {
        .clientsContainer {
            .clientCard {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}