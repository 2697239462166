@import "src/styles/_defines";

.contentSection {
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    margin: 55px auto 0px auto;
    padding: 0px 60px;
    .imgContainer {
        img {
            max-width: 100%;
            width: 100%;
        }
    }
    &.section1 {
        img {
            width: 100%;
            max-width: none;
        }
    }
    &.section2 {
        margin: 100px auto;
        .content {
            margin-right: 90px;
            h3 {
                margin-top: 10px;
            }
        }
    }
    &.section3 {
        margin-top: 140px;
        img {
            max-height: 440px;
        }
    }
    .content {
        max-width: 330px;
    }
    // Mobile
    @media (max-width: $screen-xs-max) {
        padding: 0px 25px;
        flex-direction: column !important;
        .content {
            max-width: 100%;
            h3 {
                max-width: 100% !important;
            }
        }
        .imgContainer {
            margin-top: 70px;
            text-align: center;
        }
        &.section2 {
            margin: 30px auto 0px auto;
            .content {
                margin-right: 0px;
            }
        }
        &.section3 {
            margin: 80px auto 0px auto;
        }
    }
    // Tablet
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        padding: 30px 60px;
        align-items: center;
        margin: 0px auto;
        .imgContainer {
            max-width: 50%;
        }
        .content {
            max-width: 300px;
            margin-right: 50px;
        }
        &.section2 {
            .content {
                margin-right: 0px;
            }
            .imgContainer {
                max-width: 40%;
            }
        }
        &.section3 {
            margin-top: 30px;
        }
    }
}