@import "_defines";
@import "_mixins";

$min_width: 320px;
$max_width: 1110px;
$min_font: 16px;
$max_font: 60px;
$mod_1: 1.2; // mobile
$mod_2: 1.6; // desktop

* {
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
}

h1, h2, h3, h4, h5, p, a, span, div, label, th, td, .MuiInputBase-input {
    font-family: 'Poppins', sans-serif !important;
}

h1 {  
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
  line-height: 75px;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {  
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
  font-size: $mod_1*$mod_1 *1rem;
  font-weight: 600;
  line-height: 40px;
  color: #00FF64;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1*$min_font, $mod_2*$mod_2 *$min_font);
}
p { 
  font-size: 1rem;
}
a {
  text-decoration: none;
  color: #242424;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.section {
    max-width: $max_width;
    margin: 0 auto;
}

// Buttons
.filledButton {
  .MuiButton-label {
    background: linear-gradient(15deg, rgba(0,255,100,1) 0%, rgba(0,255,181,1) 70%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
  }
}
