@import "src/styles/_defines";

.formSection {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    margin: 70px auto 130px auto !important;
    .logoBg {
      z-index: 0;
      position: absolute;
      max-width: 100%;
    }
    .eBikeForm {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: linear-gradient(rgb(0, 255, 100), rgb(0, 255, 183), rgb(0, 255, 182));
      width: 270px;
      max-width: 270px;
      border-radius: 24px;
      padding: 20px 30px;
      z-index: 1;
      box-shadow: 0px 30px 60px rgba(0, 255, 100, 0.55);
      .mtop30 {
          margin-top: 30px;
      }
      .headingTitle {
        text-align: center;
        font-size: 24px;
        margin: 10px 0px;
      }
      .MuiFormControl-root {
          width: 100%;
          background-color: #fff;
          margin-bottom: 20px;
          border-radius: 9px !important;
          .PrivateNotchedOutline-root-1 {
              top: -6px;
          }
          .MuiFilledInput-root {
            background-color: #fff;
            height: 43px;
            border-radius: 9px;
            &::before {
              top: 0;
              border-radius: 9px;
              border: 1px solid #c4c4c4;
            }
            &::after {
              display: none;
            }
          }
          .MuiInputLabel-filled {
            transform: translate(12px, 15px) scale(1);
            font-size: 13px;
            &.invisible {
              visibility: hidden;
            }
          }
          .MuiFilledInput-input {
            padding: 12px 12px 10px;
            height: 100%;
          }
          .MuiOutlinedInput-root {
            height: 43px;
            border-radius: 9px;
            &.Mui-focused {
              border-width: 0px !important;
            }
          }
          .MuiInputBase-input {
            font-size: 13px;
          }
      }
      .MuiTab-wrapper {
        text-transform: none !important;
      }
      .filledButton {
        margin: 25px 0px 15px 0px;
        min-width: 200px;
        .MuiButton-label {
          font-weight: 600;
        }
      }
    }
    @media (max-width: $screen-sm-max) {
      margin: 70px auto !important;
    }
    @media (max-width: $screen-xs-max) {
      .MuiTabs-root {
        width: 100%;
      }
    }
}

.MuiTextField-root {
  input {
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #696969;
      opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #696969;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: #696969;
    }
  }
}
