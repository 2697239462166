@import "src/styles/_defines";

.bikesSection {
    max-width: 1110px;
    margin: 30px auto;
    .newSearchIcon {
        a {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 25px;
            margin-left: 15px;
            svg {
                margin-right: 10px;
            }
        }
    }
    .noResultsFound {
        margin-top: 60px;
        display: flex;
        justify-content: center;
    }
    .top3BikesContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        .bikeCard {
            max-width: 250px;
            border-radius: 43px;
            padding: 50px;
            box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.16);
            margin-right: 30px;
            height: 425px;
            .imgContainer {
                display: flex;
                justify-content: center;
                img {
                    max-width: 100%;
                    max-height: 180px;
                    min-height: 180px;
                }
            }
            &:last-child {
                margin-right: 0px;
            }
            .bestChoiceContainer {
                width: 220px;
                background: linear-gradient(15deg, rgba(0,255,181,1) 0%, rgba(0,255,100,1) 70%);
                height: 50px;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: -75px 20px;
                border-radius: 30px;
                h4 {
                    font-size: 19px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }
            h3 {
                color: #242424;
                max-height: 120px;
                min-height: 87px;
                margin: 10px 0px 30px 0px;
                line-height: 41px;
                font-size: 36px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                -webkit-box-orient: vertical;
            }
            &.bestChoiceBike {
                box-shadow: 0px 0px 40px #00FF64;
            }
            .descriptionRow {
                display: flex;
                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1; /* number of lines to show */
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
    .top3BikesSpecs {
        margin-top: 100px;
        .top3BikeNamesContainer {
            display: flex;
            justify-content: flex-end;
            .bikeName {
                h4 {
                    font-size: 28px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    margin-bottom: 20px;
                    max-width: 94%;
                }
                &.quarterContainer {
                    width: 27%;
                }
                &.halfContainer {
                    width: 40.5%;
                }
                &.fullContainer {
                    width: 81%;
                }
            }
        }
        .MuiAccordionSummary-content {
            p {
                margin: 0;
                font-size: 20px;
            }
        }
        .MuiAccordionDetails-root {
            padding: 0;
        }
        .bikeTableComponent {
            width: 100%;
            th {
                width: 19%;
                min-width: 19%;
                color: #AAA;
            }
            td {
                border-left: 1px solid #EAEAEA;
                &.toCapitalize {
                    text-transform: capitalize;
                }
            }
            &.quarterTable {
                td {
                    width: 27%;
                }
            }
            &.halfTable {
                td {
                    width: 40.5%;
                }
            }
            &.fullTable {
                td {
                    width: 81%;
                }
            }
        }
    }
    // Max laptop
    @media (max-width: $screen-md-max) {
        padding: 0px 16px;
        .top3BikesContainer {
            flex-wrap: wrap;
            justify-content: space-evenly;
            padding: 0px 20px;
            .bikeCard {
                max-width: initial;
                margin-right: 0px;
                margin-bottom: 40px;
                max-width: 36%;
                height: 450px;
                .imgContainer {
                    img {
                        max-height: 220px;
                    }
                }
                &:last-child {
                    margin-bottom: 0px;
                }
                .bestChoiceContainer {
                    margin: -75px 0px;
                }
            }
        }
    }
    // Max smartphone
    @media (max-width: $screen-sm-max) {
        .top3BikesSpecs {
            .top3BikeNamesContainer {
                .bikeName {
                    h4 {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {
        .top3BikesContainer {
            .bikeCard {
                max-width: 100%;
                min-width: auto;
                height: 425px;
                .imgContainer {
                    img {
                        max-height: 180px;
                    }
                }
            }
        }
        .top3BikesSpecs {
            .top3BikeNamesContainer {
                .bikeName {
                    &.quarterContainer {
                        width: 25%;
                    }
                }
            }
        }
    }
    .infoIconContainer {
        position: relative;
        display: flex;
        align-items: center;
        margin-left: 5px;
        .infoIcon {
            width: 20px;
            height: 20px;
        }
        .tooltiptext {
            visibility: hidden;
            width: 200px;
            background-color: #555;
            color: #fff;
            text-align: center;
            padding: 5px 0;
            border-radius: 6px;
    
            /* Position the tooltip text */
            position: absolute;
            z-index: 1;
            bottom: 125%;
            left: 50%;
            margin-left: -100px;
    
            /* Fade in tooltip */
            opacity: 0;
            transition: opacity 0.3s;
    
            &:after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: #555 transparent transparent transparent;
            }
        }
        &:hover {
            .tooltiptext {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}