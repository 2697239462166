@import "src/styles/_defines";

.homepageContainer {
  .header {
    background: linear-gradient(
      15deg,
      rgba(0, 255, 100, 1) 0%,
      rgba(0, 255, 181, 1) 70%
    );
    min-height: 830px;
    display: flex;
    flex-direction: column;
    .headerImage {
      background: url("hero-img.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      min-width: 50%;
      min-height: 830px;
      position: absolute;
      right: 0;
    }
    .contentContainer {
      width: 1100px;
      margin: auto;
      display: flex;
      flex-direction: column;
      padding: 100px 60px 60px 60px;
      .logoImg {
        position: absolute;
        top: 45px;
        width: 255px;
      }
      .content {
        max-width: 505px;
        p {
          line-height: 40px;
          font-size: 24px;
        }
      }
      h1 {
        font-size: 58px;
      }
    }
  }
  .bikeBrandLogos {
    flex-direction: column;
    .brandsContainer {
      display: flex;
      flex-wrap: wrap;
      .brandLogo {
        margin-right: 60px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        img {
          max-width: 100px;
        }
        &.logo_SmartMotion {
          img {
            max-width: 200px;
          }
        }
        &.logo_Trek {
          img {
            max-width: 150px;
          }
        }
        &.logo_Kalkhoff {
          img {
            max-width: 200px;
          }
        }
        &.logo_Gazelle {
          img {
            max-width: 150px;
          }
        }
        &.logo_Leitner {
          img {
            max-width: 200px;
          }
        }
        &.logo_Electra {
          img {
            max-width: 170px;
          }
        }
        &.logo_Orbea {
          img {
            max-width: 200px;
          }
        }
        &.logo_Ordica {
          margin-right: 90px;
          margin-left: 20px;
          img {
            max-width: 60px;
          }
        }
        &.logo_Dyson {
          margin-right: 120px;
          img {
            max-width: 120px;
          }
        }
        &.logo_Renag {
          img {
            max-width: 180px;
          }
        }
        &.logo_Gospringo {
          img {
            max-width: 150px;
          }
        }
        &.logo_Zoomo {
          margin-right: 110px;
          img {
            max-width: 150px;
          }
        }
        &.logo_Mondraker {
          img {
            max-width: 180px;
          }
        }
        &.logo_Merida {
          img {
            max-width: 150px;
          }
          margin-bottom: 10px;
        }
        &.logo_Focus {
          margin-bottom: 10px;
        }
        &.logo_Kaabo {
          margin-right: 110px;
          img {
            max-width: 150px;
          }
          margin-bottom: 10px;
        }
        &.logo_e-Glide {
          img {
            max-width: 180px;
          }
          margin-bottom: 10px;
        }
        &.logo_Segway {
          img {
            max-width: 150px;
          }
        }
        &.logo_Xiaomi {
          margin-right: 80px;
          margin-left: 20px;
          img {
            max-width: 60px;
          }
        }
        &.logo_Mercane {
          margin-right: 140px;
          img {
            max-width: 120px;
          }
        }
        &.logo_Bird {
          margin-right: 150px;
          img {
            max-width: 90px;
          }
        }
        &.logo_OKAI {
          img {
            max-width: 150px;
          }
        }
        &.logo_Weped {
          margin-right: 140px;
          img {
            max-width: 120px;
          }
        }
        &.logo_Unagi {
          margin-right: 140px;
          img {
            max-width: 120px;
          }
        }
        &.logo_Inokim {
          margin-top: -40px;
          img {
            max-width: 150px;
          }
        }
        &.logo_Machine {
          margin-top: -40px;
        }
      }
    }
  }
  .appIconContainer {
    display: flex;
    justify-content: center;
    max-width: 920px;
    margin: 60px auto;
  }
  .contactFormContainer {
    max-width: 920px;
    margin: 40px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h3 {
      margin: 0;
    }
    p {
      margin: 5px;
    }
    .contactForm {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      margin-top: 70px;
      .personalDetailsRow {
        display: flex;
        width: 100%;
        .nameField {
          width: 50%;
          margin-right: 50px;
        }
        .emailField {
          width: 50%;
          margin-left: 50px;
        }
      }
      .messageContainer {
        display: flex;
        width: 100%;
        margin-top: 50px;
        .messageField {
          width: 100%;
        }
      }
      .MuiInput-underline {
        &:before {
          border-bottom: 2px solid #00ff64;
        }
      }
      .btnSend {
        margin-top: 60px;
        min-width: 200px;
        border-radius: 30px;
        height: 50px;
        text-transform: none;
        border: 3px solid #00ff64;
      }
    }
  }
  // Smartphone
  @media (max-width: $screen-xs-max) {
    .header {
      .contentContainer {
        padding: 100px 30px 60px 30px;
        .logoImg {
          max-width: 145px;
          align-self: center;
        }
        h1 {
          font-size: 41px;
          line-height: 60px;
        }
      }
      .headerImage {
        background-position: top !important;
        min-width: 100% !important;
        position: relative !important;
        min-height: 417px !important;
      }
    }
    .contactFormContainer {
      .contactForm {
        .personalDetailsRow {
          flex-direction: column;
          .nameField,
          .emailField {
            width: auto;
            margin: 0px 25px;
          }
          .emailField {
            margin-top: 50px;
          }
        }
        .messageContainer {
          .messageField {
            margin: 0px 25px;
          }
        }
      }
    }
    .bikeBrandLogos {
      .brandsContainer {
        .brandLogo {
          margin-right: 0px;
          img {
            width: 120px;
            margin: 20px;
          }
          &.logo_Ordica {
            margin-right: 0px;
            margin-left: 0px;
          }
          &.logo_Dyson {
            margin-right: 0px;
          }
          &.logo_Zoomo {
            margin-right: 0px;
          }
          &.logo_Kaabo {
            margin-right: 0px;
          }
          &.logo_Mercane {
            margin-right: 0px;
          }
          &.logo_Bird {
            margin-right: 0px;
          }
          &.logo_Xiaomi {
            margin-right: 0px;
            margin-left: 0px;
          }
          &.logo_Weped {
            margin-right: 0px;
            margin-left: 0px;
          }
          &.logo_Unagi {
            margin-right: 0px;
            margin-left: 0px;
          }
        }
      }
    }
  }
  // Tablet
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .header {
      min-height: 630px !important;
      .contentContainer {
        .logoImg {
          max-width: 145px;
          align-self: center;
        }
      }
      .headerImage {
        min-height: 630px !important;
        opacity: 0.2 !important;
        position: absolute;
        background-position: right;
        min-width: 50%;
      }
    }
    .contactFormContainer {
      padding: 0px 60px;
    }
    .bikeBrandLogos {
      align-items: flex-start;
      .brandsContainer {
        .brandLogo {
          margin-right: 0px;
          img {
            width: 150px;
            margin: 20px;
          }
          &.logo_Ordica {
            margin-right: 70px;
          }
          &.logo_Dyson {
            margin-right: 0px;
          }
          &.logo_Kaabo {
            margin-right: 0px;
          }
          &.logo_Mercane {
            margin-right: 0px;
          }
          &.logo_Unagi {
            margin-right: 0px;
          }
          &.logo_Inokim {
            margin-top: 0px;
          }
          &.logo_Machine {
            margin-top: 0px;
          }
        }
      }
    }
  }
  @media (max-width: $screen-md-max) {
    .header {
      min-height: 730px;
      .contentContainer {
        max-width: 100%;
        width: auto;
        margin: auto 0px;
      }
      .headerImage {
        min-height: 730px;
        opacity: 1;
      }
    }
  }
  @media (max-width: $screen-lg-max) {
    .header {
      min-height: 830px;
      .headerImage {
        min-height: 830px;
      }
    }
  }
}

.dialogFeedbackMessage {
  .MuiDialog-paperScrollPaper {
    border-radius: 30px;
    padding: 25px 25px 35px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    .contentContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 20px 0px 30px 0px;
      h4 {
        margin: 0;
        font-size: 23px;
        font-weight: 400;
      }
      p {
        margin-bottom: 0px;
        color: #000;
      }
    }
  }
}
